import {checkEmergency, loadBroadcastNotification} from "../store/cards";

function startBackgroundTasks(dispatch) {
    let reloadPage = false;
    let timer;
    document.addEventListener('visibilitychange', function () {
        // clearTimeout(timer);
        // if (document.visibilityState === 'hidden') {
        //     //begin timer when user closes app
        //     timer = setTimeout(() => {
        //         reloadPage = true;
        //     }, 5 * 60 * 1000); // 5 minutes
        //     return;
        // }
        // if (reloadPage) {
        //     //reload page if app wasn't running for 5 minutes
        //     window.location.reload(); 
        //     return;
        // }
        if (document.visibilityState === 'visible') {
            //fetch messages when opening the app withing 5 minutes of closing it
            dispatch(loadBroadcastNotification()); 
            dispatch(checkEmergency());
            return;
        }
    });

    // setInterval(() => {
    //     //fetch messages every 60 seconds while the app is running
    //     dispatch(loadBroadcastNotification());
    //     dispatch(checkEmergency());
    // }, 60000);
}

export {startBackgroundTasks}