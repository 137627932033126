import {useTranslation} from 'react-i18next';
import ButtonBack from './ButtonBack/ButtonBack';
import ButtonClose from './ButtonClose/ButtonClose';
import {Typography} from '../Typography';
import LanguageSwitcher from '../../ProfilePage/Settings/AppSettings/LanguageSwitcher';
/**
 *
 * @param {boolean} disableBoxShadow = boolean to disabled the box shadow
 * @param {function} backButtonClickHandler = callback function triggered when backButton is clicked -> if there is no handler the button is not displayed
 * @param {string} headerTranslationKey = translation key for middle text -> if there is no text key the header is not displayed
 * @param {function} closeButtonClickHandler = callback function triggered when closebutton is clicked -> if there is no handler the button is not displayed
 * @param {ReactComponent} child = react component to be displayed under secondary header
 * @param {ReactComponent} childUnderHeader = react component to be displayed right under header
 * @param {ReactComponent} childNextToHeader = react component to be displayed right next to the header title
 * @param {ReactComponent} childNextToHeaderClick = callback function to be called when element right next to the header title is clicked
 * @returns
 */
// same component as Header.js but the elements are on the same line and the heading has no line underneath.
function SecondaryHeader({
	disableBoxShadow,
	backButtonClickHandler,
	headerTranslationKey,
	closeButtonClickHandler,
	child,
	childUnderHeader,
	childNextToHeader,
	childNextToHeaderClick,
	childNextToHeaderDisabled,
	backButtonText = '',
	closeButtonText = '',
}) {
	const {t} = useTranslation();

	return (
		<>
			<div className={'secondary-header-wrapper' + (disableBoxShadow ? ' disable-box-shadow' : '')}>
				<div className="secondary-header-back-button">
					{backButtonClickHandler && <ButtonBack backButtonText={backButtonText} clickHandler={backButtonClickHandler} />}
				</div>
				<div className="secondary-header-main-heading">
					{headerTranslationKey && (
						<div className="header-wrapper">
							<Typography className={'header'}>{t(headerTranslationKey)}</Typography>
							{childUnderHeader}
						</div>
					)}
				</div>

				<div
					className={childNextToHeaderDisabled ? ' secondary-header-options secondary-header-options-disabled' : 'secondary-header-options'}
					onClick={childNextToHeaderClick}
					disabled={childNextToHeaderDisabled}>
					{childNextToHeader || closeButtonClickHandler ? (
						<>
							{closeButtonClickHandler && <ButtonClose closeButtonText={closeButtonText} clickHandler={closeButtonClickHandler} />}
							{childNextToHeader}
						</>
					) : (
						<LanguageSwitcher />
					)}
				</div>
			</div>
			{child}
		</>
	);
}

export default SecondaryHeader;
