import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import Select, {components} from 'react-select';

import {changeUserLanguage} from '../../../../../store/auth';
import sortAlphabetically from '../../../../../functions/sortAlphabetically';
import Icon from '../../../../Common/Icon';

function LanguageSwitcher(props) {
	const {i18n} = useTranslation();
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.auth.data.userData);
	const {Option} = components;

	const isLCOrganization = userData?.organization?._id === '65e0889052babfd96450b1d4'; //Dev 6086f5a6cc0824cc4ce7c9ed | LC 65e0889052babfd96450b1d4

	let options = [
		{value: 'en', label: 'EN'},
		{value: 'de', label: 'DE'},
		{value: 'fr', label: 'FR'},
	];

	if (isLCOrganization) {
		options = [
			{value: 'en', label: 'EN'},
			{value: 'fr', label: 'FR'},
		];
	}

	options = sortAlphabetically(options, 'label');

	const matchLanguage = (language) => {
		let label;
		switch (language) {
			case 'en-US':
				label = 'EN';
				break;
			case 'en':
				label = 'EN';
				break;
			case 'fr':
				label = 'FR';
				break;
			case 'de':
				label = 'DE';
				break;
			default:
				label = 'EN';
				break;
		}

		return label;
	};

	const CustomOption = (props) => (
		<Option {...props} className={'custom-option'}>
			{props.isSelected ? (
				<div style={{width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<Icon iconName={'check'} iconType={'far'} style={{width: '15px', fontSize: '15px'}} />
				</div>
			) : (
				<div style={{width: '30px'}}></div>
			)}
			<span className={'custom-option-label'}>{props.data.label}</span>
		</Option>
	);

	let langCode = userData?.language || localStorage.getItem('defaultLanguage');
	let currentLangLabel = matchLanguage(langCode);
	let defaultValue = {value: langCode, label: currentLangLabel};

	const passLanguageCodeToNativeApp = (langCode) => {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					locale: langCode,
				}),
			);
		}
	};

	const handleChange = (e) => {
		dispatch(changeUserLanguage(e.value));
		passLanguageCodeToNativeApp(e.value);
	};

	return (
		<Select
			className={'languageSwitcher'}
			options={options}
			styles={languageDropdownStyle}
			defaultValue={defaultValue}
			onChange={handleChange}
			placeholder={matchLanguage(i18n?.language)}
			isSearchable={false}
			components={{Option: CustomOption}}
		/>
	);
}

// overriding default component according to current design system

const languageDropdownStyle = {
	indicatorsContainer: (styles) => ({
		...styles,
		'pointer-events': 'none',
		color: 'black',
	}),

	valueContainer: (styles) => ({
		...styles,
		width: '59px',
		zIndex: '0',
	}),

	singleValue: () => ({
		color: '#333333',
		gridArea: '1/1/2/3',
		textAlign: 'right',
	}),
	container: (styles) => ({
		...styles,
	}),

	control: (styles, state) => ({
		...styles,
		backgroundColor: 'transparent',
		minHeight: '38px',
		border: 'none',
		boxShadow: 'none',
		':hover': {
			...styles[':hover'],
			outline: '0!important',
			borderColor: '#333333',
			boxShadow: 'none',
		},
		':focus': {
			...styles[':focus'],
			outline: '0!important',
			borderColor: '#333333',
			boxShadow: 'none',
		},
		':active': {
			...styles[':active'],
			outline: '0!important',
			borderColor: '#333333',
			boxShadow: 'none',
		},
	}),

	menu: (provided, state) => ({
		...provided,
		zIndex: '10',
	}),

	menuList: (provided, state) => ({
		...provided,
		padding: 0,
		marginTop: 0,
		marginBottom: 0,
		maxHeight: '220px',
		overflowY: 'auto',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
		color: '#333333',
		padding: '0px',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	option: (styles, {isDisabled, isFocused, isSelected}) => {
		const whiteColor = '#fff';
		const blackColor = '#000';
		const greyColor = '#333333';

		return {
			...styles,

			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',

			backgroundColor: isDisabled ? null : isSelected ? whiteColor : isFocused ? whiteColor : null,

			color: isDisabled ? greyColor : isSelected ? blackColor : greyColor,

			cursor: isDisabled ? 'not-allowed' : 'default',

			'.icon': {
				width: '25px',
			},
			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? whiteColor : whiteColor),
			},

			':hover': {
				...styles[':hover'],
				backgroundColor: !isDisabled && (isSelected ? '#E7E7E7' : '#E7E7E7'),
			},
		};
	},
};

export default LanguageSwitcher;
