export const redThemeColors = {
	colorMainTheme: '#B00020',
	colorMain0: '#6B0009',
	colorMain10: '#87000B',
	colorMain20: '#970013',
	colorMain30: '#A00018',
	colorMain40: '#A9001C',
	colorMain50: '#B00020',
	colorMain60: '#BC2641',
	colorMain70: '#C84D63',
	colorMain80: '#D88090',
	colorMain90: '#E7B3BC',
	colorMain95: '#F6E0E4',
	colorNeutral50: '#333333',
	colorNeutral60: '#5a5959',
	colorNeutral80: '#999999',
	colorNeutral95: '#E7E7E7',
	colorYellow50: '#FFAB25',
	mainThemeIconColor: 'icon-purple',
	iconColor: 'icon-rose',
	angleRight: 'icon-white',
	deskListIconArray: 'icon-lightMagenta',
	deskIconStroke: 'icon-desk-stroke',
	deskIconFill: 'icon-desk-fill',
	roomIconStroke: 'icon-room-stroke',
	bookingIconStroke: 'booking-icon-stroke',
	bookingDeskIconStroke: 'icon-booking-desk-stroke',
	bookingDeskIconFill: 'icon-booking-desk-fill',
	nextBookingsDeskIconStroke: 'icon-next-bookings-desk-stroke',
	customIconDefaultStroke: 'icon-default-stroke',
	customIconDefaultFill: 'icon-default-fill',
	favoriteIconColor: 'icon-favorite',
	favoriteIconActiveColor: 'icon-active-favorite',
	dashboardIconColor: 'icon-gray',
	sortingIconFill: 'icon-fill-purple',
};
