export const blueThemeColors = {
	colorMainTheme: '#00549a',
	colorMain0: '#000e1a',
	colorMain10: '#00325b',
	colorMain20: '#004680',
	colorMain30: '#0059a4',
	colorMain40: '#007de6',
	colorMain50: '#00549a',
	colorMain60: '#229bff',
	colorMain70: '#4daeff',
	colorMain80: '#80c5ff',
	colorMain90: '#b3dcff',
	colorMain95: '#e5f3ff',
	colorNeutral50: '#333333',
	colorNeutral60: '#5a5959',
	colorNeutral80: '#999999',
	colorNeutral95: '#E7E7E7',
	colorYellow50: '#FFAB25',
	mainThemeIconColor: 'icon-purple',
	iconColor: 'icon-rose',
	angleRight: 'icon-white',
	deskListIconArray: 'icon-lightMagenta',
	deskIconStroke: 'icon-desk-stroke',
	deskIconFill: 'icon-desk-fill',
	roomIconStroke: 'icon-room-stroke',
	bookingIconStroke: 'booking-icon-stroke',
	bookingDeskIconStroke: 'icon-booking-desk-stroke',
	bookingDeskIconFill: 'icon-booking-desk-fill',
	nextBookingsDeskIconStroke: 'icon-next-bookings-desk-stroke',
	customIconDefaultStroke: 'icon-default-stroke',
	customIconDefaultFill: 'icon-default-fill',
	favoriteIconColor: 'icon-favorite',
	favoriteIconActiveColor: 'icon-active-favorite',
	dashboardIconColor: 'icon-gray',
	sortingIconFill: 'icon-fill-purple',
};
