import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = { cancelToken: source.token };

const pathUrls = {
	getImages: '/getImages',
};

export async function getOffices() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
		},
		config,
	);
}

export async function getOfficesGrouped() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + apiUrl.path.findMany + apiUrl.path.grouped;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getBannerForOffice(officeId, variation) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + '/' + officeId + apiUrl.path.banner + `?variation=800`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

export async function getBroadcastNotification(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + '/' + officeId + apiUrl.path.broadcast;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function hideFromBroadcast(officeId, broadcastId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.office + '/' + officeId + apiUrl.path.broadcast + apiUrl.path.hide;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				broadcastId,
			},
		},
		config,
	);
}

export async function getEmergencyNotification(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + '/' + officeId + apiUrl.path.emergencyNotification;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function updateEmergencyNotification(memberId, crisisId, response, latestLocation) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.oakCrisis + crisisId + apiUrl.path.updateMemberResponse;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				response,
				latestLocation
			},
		},
		config,
	);
}

export async function getAdditionalDataForOffice(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + '/' + officeId;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export async function getListOfAllOfficeElements(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.office + '/' + officeId + apiUrl.path.areaMapElement + '/list';

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {string} officeId = office id assigned to area map element
 * @param {string} areaMapElementId = area map element id for which to retrieve data
 * In the request query, we get all the data for a specific area map element
 * @returns
 */

export async function getAreaMapElement(officeId, code) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = `${hostUrl + apiUrl.path.office}/${officeId + apiUrl.path.areaMapElement}?identifier=${code}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

/**
 *
 * @param {string} officeId = office id assigned to area map element
 * @param {string} areaMapElementId = area map element id for which to retrieve photos
 * In the request query, we get all the photos for a specific area map element
 * @returns
 */
export async function getAreaMapElementPhotos(officeId, areaMapElementId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = `${hostUrl + apiUrl.path.office}/${officeId + apiUrl.path.areaMapElement}/${areaMapElementId + pathUrls.getImages}`;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}
